<template>
  <div class="technology">
    <div class="banner">
      <div class="left">
        <div class="title_box">
          <div class="big_title">数字化 · 平台化 · 智能化 · 移动化</div>
          <div class="small_title">高质、高效、安全的技术支撑</div>
        </div>
      </div>
    </div>
    <div class="technology_contact">
      <div class="title">
        <img src="../../../assets/keleteyi.png" alt="图标" class="kelete"> 技术架构平台 </div>
      <div class="subtitle">克里特数字化平台自研发多种数字化技术支撑，让企业管理更加高效安全。</div>
      <div class="bg"></div>
    </div>
    <div style="background-color: #f9f9f9;" class="technology_contact">
      <div class="title">    <img src="../../../assets/keleteyi.png" alt="图标" class="kelete"> 安全架构 </div>
      <div class="subtitle">克里特数字化平台自研发多种安全技术架构，保证企业管理的稳定性和安全性。</div>
      <div class="bg2"></div>
    </div>
    <div class="technology_contact">
      <div class="title"> <img src="../../../assets/keleteyi.png" alt="图标" class="kelete"> 技术底座 </div>
      <div class="subtitle">通过JVM中动态加载技术，自创框架，实现功能组件化，业务插件化，并支持热插拔。</div>
      <div class="bg3"></div>
    </div>
    <div style="background-color: #f9f9f9;" class="technology_contact">
      <div style="margin-bottom: 0.84rem;" class="title"> <img src="../../../assets/keleteyi.png" alt="图标" class="kelete"> 产品引擎 </div>
      <div class="container">
        <div class="container_top">
          <div style="display: flex;justify-content: space-between;width: 80%;">
            <div class="container_top_inside" v-for="(item, index) in productEngineList" :key="index">
              <div @click="changeProductEngineList(index)" :class="{ active: productEngineActive === index }"
                class="item">
                <div v-if="productEngineActive === index" class="item_img"
                  :style="index === 0 ? { 'width': '0.6rem', 'height': '0.8rem' } : {}">
                  <img :src="item.selectIcon" />
                </div>
                <div v-else class="item_img" :style="index === 0 ? { 'width': '0.6rem', 'height': '0.8rem' } : {}">
                  <img :src="item.icon" />
                </div>
                <div class="item_text">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="productEngineActive === 0" class="conatiner_bottom">
          <div class="conatiner_bottom_left">
            <div class="top">表单引擎</div>
            <div class="bottom">不需要任何的专业知识，只需要轻点鼠标，拖动组件，像搭积木一样，轻松完成表单的创建，一分钟开始数据收集的工作。</div>
          </div>
          <div class="container_bottom_right1"></div>
        </div>
        <div v-show="productEngineActive === 1" class="conatiner_bottom">
          <div class="conatiner_bottom_left" style="height: 3.2rem;">
            <div class="top">流程引擎</div>
            <div class="bottom">再繁琐的流程，我们都能帮您图形化来设计,在线设计流程与设计表单无缝对接，轻松实现流程设计</div>
          </div>
          <div class="container_bottom_right2"></div>
        </div>
        <div v-show="productEngineActive === 2" class="conatiner_bottom">
          <div class="conatiner_bottom_left">
            <div class="top">规则引擎</div>
            <div class="bottom">通过可视化界面，开发人员和业务用户自定义和管理决策流程，包括条件分支、并行处理、嵌套规则，使业务逻辑的管理更加灵活和可迭代</div>
          </div>
          <div class="container_bottom_right3"></div>
        </div>
      </div>
    </div>
    <div class="technology_contact">
      <div style="margin-bottom: 0.84rem;" class="title"> <img src="../../../assets/keleteyi.png" alt="图标" class="kelete"> 文档协同 </div>
      <div class="container">
        <div class="container_top">
          <div style="display: flex;justify-content: space-between;width: 50%;">
            <div class="container_top_inside" v-for="(item, index) in pocumentCollaborationList" :key="index">
              <div @click="changePocumentCollaborationList(index)"
                :class="{ active: pocumentCollaborationActive === index }" class="item">
                <div v-if="pocumentCollaborationActive === index" class="item_img">
                  <img :src="item.selectIcon" />
                </div>
                <div v-else class="item_img">
                  <img :src="item.icon" />
                </div>
                <div class="item_text">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="pocumentCollaborationActive === 0" class="conatiner_bottom">
          <div class="conatiner_bottom_left">
            <div class="top">文档协同</div>
            <div class="bottom">多个人共同编辑、审阅和管理文档的过程，，提高团队成员之间的沟通效率，促进信息共享和合作，确保文档的准确性、-致性和时效性。</div>
          </div>
          <div class="container_bottom_right4"></div>
        </div>
        <div v-show="pocumentCollaborationActive === 1" class="conatiner_bottom">
          <div class="conatiner_bottom_left" style="height: 3.2rem;">
            <div class="top">在线文档</div>
            <div class="bottom">支持处理处理文本文档、表格、演示文稿、表单以及媒.体文件，完美整合OFFICE, WPS。</div>
          </div>
          <div class="container_bottom_right5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productEngineActive: 0,
      productEngineList: [
        {
          name: '表单引擎',
          icon: require('../../../assets/表单引擎.png'),
          selectIcon: require('../../../assets/表单引擎选中.png')
        },
        {
          name: '流程引擎',
          icon: require('../../../assets/流程引擎.png'),
          selectIcon: require('../../../assets/流程引擎选中.png')
        },
        {
          name: '规则引擎',
          icon: require('../../../assets/规则引擎.png'),
          selectIcon: require('../../../assets/规则引擎选中.png')
        },
      ],
      pocumentCollaborationActive: 0,
      pocumentCollaborationList: [
        {
          name: '文档协同',
          icon: require('../../../assets/文档协同.png'),
          selectIcon: require('../../../assets/文档协同选中.png')
        },
        {
          name: '在线文档',
          icon: require('../../../assets/在线文档.png'),
          selectIcon: require('../../../assets/在线文档选中.png')
        },
      ]
    }
  },
  methods: {
    changeProductEngineList(index) {
      this.productEngineActive = index
    },
    changePocumentCollaborationList(index) {
      this.pocumentCollaborationActive = index
    }
  }
}
</script>

<style lang="scss" scoped>
.technology {
  .banner {
    min-width: 1024px;
    width: 100%;
    height: 20rem;
    background-image: url('../../../assets/technologyBanner.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 43%;
      height: 70%;
      //background: red;
      padding-left: 2.8rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title_box {
        height: 4.1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .big_title {
          font-weight: 600;
          font-size: 1.6rem;
          color: #000000;
        }

        .small_title {
          font-weight: 500;
          font-size: 0.82rem;
          color: #000000;
        }
      }
    }
  }

  .technology_contact {
    min-width: 1024px;
    width: 100%;
    padding-top: 3.2rem;
    padding-bottom: 4.8rem;
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-bottom: 0.6rem;
      font-size: 0.88rem;
      font-weight: 600;
      display: flex;
      align-items: center; /* 垂直居中对齐 */
        }
    .kelete{
      width: 90px;
      margin-right: 5px;
    }
    .subtitle {
      font-size: 0.56rem;
      color: #999999;
      margin-bottom: 1.2rem;
    }

    .bg {
      width: 79%;
      height: 32.4rem;
      background-image: url('../../../assets/technology2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .bg2 {
      width: 79%;
      height: 32.4rem;
      background-image: url('../../../assets/technology3.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .bg3 {
      width: 79%;
      height: 32.4rem;
      background-image: url('../../../assets/technology4.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .container {
      width: 85%;

      .container_top {
        border-bottom: 0.04rem solid #dbdbdb;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 1.6rem;

        .container_top_inside {
          width: 32%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          color: #333333;

          .item {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 3.6rem;
            height: 1.48rem;
            cursor: pointer;

            .item_img {
              width: 0.7rem;
              height: 0.7rem;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .item_text {
              font-size: 0.56rem;
            }
          }

          .active {
            color: #4e78ff;
            border-bottom: 0.12rem solid #4e78ff;
          }
        }
      }

      .conatiner_bottom {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .conatiner_bottom_left {
          width: 27%;
          height: 4.16rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .top {
            font-size: 0.8rem;
            font-weight: 600;
            color: #333333;
          }

          .bottom {
            font-size: 0.56rem;
            color: #999999;
            line-height: 0.8rem;
          }
        }

        .container_bottom_right1 {
          width: 65%;
          height: 19.64rem;
          background-image: url('../../../assets/表单引擎大图.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .container_bottom_right2 {
          width: 65%;
          height: 19.64rem;
          background-image: url('../../../assets/流程引擎大图.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .container_bottom_right3 {
          width: 65%;
          height: 19.64rem;
          background-image: url('../../../assets/规则引擎大图.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .container_bottom_right4 {
          width: 65%;
          height: 19.64rem;
          background-image: url('../../../assets/文档协同大图.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .container_bottom_right5 {
          width: 65%;
          height: 19.64rem;
          background-image: url('../../../assets/在线文档.jpg');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
</style>